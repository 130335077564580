<template>
  <div>
    <bread-crumb
      :crumbData="['菜单', '新增']"
      :chooseCrumbData="2"
      @close="onClose"
    ></bread-crumb>

    <div style="padding: 30px 100px">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="位置：" prop="location">
          <el-radio-group v-model="ruleForm.location">
            <el-radio :label="0">顶部菜单</el-radio>
            <el-radio :label="1">分类导航</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="名称：" prop="nav_name">
          <el-input v-model="ruleForm.nav_name"></el-input>
        </el-form-item>
        <el-form-item label="缩略图：" prop="thumbnail">
          <el-upload
            class="avatar-uploader"
            action="#"
            :show-file-list="false"
            :http-request="httpRequest1"
          >
            <img
              v-if="ruleForm.thumbnail"
              :src="'http://192.168.3.3' + ruleForm.thumbnail"
              class="avatar"
            />
            <el-button size="small" type="primary" class="uploadBtu"
              >点击上传</el-button
            >
          </el-upload>
        </el-form-item>
        <el-form-item label="导航标签：">
          <el-select clearable v-model="ruleForm.nav_label" locationholder="无">
            <el-option
              v-for="(item, index) in nav_labelOpts"
              :key="index"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类型：" prop="template_type">
          <el-radio-group v-model="ruleForm.template_type">
            <el-radio
              v-for="(item, index) in types"
              :key="index"
              :label="item"
            ></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="ruleForm.template_type == '商品'"
          label="商品分类："
        >
          <el-select
            clearable
            v-model="ruleForm.product_category"
            locationholder="无"
          >
            <el-option
              v-for="item in goodsClassifies"
              :key="item.id"
              :label="item.classify_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="ruleForm.template_type == '标签'"
          label="商品标签："
        >
          <el-select
            clearable
            v-model="ruleForm.product_label"
            locationholder="无"
          >
            <el-option
              v-for="(item, index) in goodsTages"
              :key="index"
              :label="item"
              :value="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          style="margin-left: -23px"
          label-width="120px"
          v-if="ruleForm.template_type == '设计器子页'"
          label="子页Id(pageId)："
        >
          <el-input v-model="ruleForm.page_id"></el-input>
        </el-form-item>
        <el-form-item label="路径：">
          <el-input v-model="ruleForm.path"></el-input>
        </el-form-item>
        <el-form-item label="分组：">
          <el-input v-model="ruleForm.group"></el-input>
        </el-form-item>
        <el-form-item label="排序：">
          <el-input v-model="ruleForm.sort"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  props: ["data"],
  data() {
    return {
      nav_labelOpts: [],
      types: [
        "商品",
        "新品",
        "拼团",
        "秒杀",
        "砍价",
        "套餐",
        "首页",
        "标签",
        "叫水",
        "设计器子页",
        "自定义",
      ],
      goodsClassifies: [],
      goodsTages: ["精选", "新品", "推荐"],
      ruleForm: {
        location: "",
        nav_name: "",
        thumbnail: "",
        nav_label: "",
        template_type: "",
        product_category: "",
        product_label: "",
        page_id: "",
        path: "",
        group: "",
        sort: "",
        commercial_id: "",
        id: 0,
      },
      rules: {
        location: [{ required: true, message: "必填字段", trigger: "change" }],
        nav_name: [{ required: true, message: "必填字段", trigger: "blur" }],
        template_type: [
          { required: true, message: "必填字段", trigger: "change" },
        ],
      },
    };
  },
  mounted() {
    if (this.$props.data) {
      this.ruleForm = this.$props.data;
    }
    this.ruleForm.commercial_id =
      localStorage.getItem("commercial_id") ||
      sessionStorage.getItem("commercial_id");
    this.$http
      .post("/Goods_Category/lst", {
        commercial_id: this.ruleForm.commercial_id,
      })
      .then((res) => {
        this.goodsClassifies = res.data;
      });
  },
  computed: {
    isType() {
      return true;
    },
  },
  methods: {
    onClose(data) {
      this.$emit("close", data);
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http
            .post("/content/nav_management/save", this.ruleForm)
            .then((res) => {
              if (res.data.status == "success") {
                this.onClose(0);
                this.$message({
                  type: "success",
                  message: res.data.msg,
                });
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    httpRequest1(file) {
      this.getBase64(file.file).then((res) => {
        this.$http
          .post("uploads_images/up", this.$qs.stringify({ file: res }))
          .then((ress) => {
            this.ruleForm.thumbnail = ress.data.path;
          });
      });
    },
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },
  },
};
</script>
<style scoped lang="less">
.avatar {
  width: 78px;
  height: 78px;
  display: block;
  padding: 5px;
  border: 1px solid #dddddd;
  pointer-events: none !important;
}

/deep/ .avatar-uploader .el-upload {
  border: 0;
  position: relative;
  overflow: hidden;
  pointer-events: none;
}

.uploadBtu {
  pointer-events: auto !important;
  margin-top: 15px !important;
}
</style>
